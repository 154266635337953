export { matchers } from './client-matchers.js';

export const nodes = [() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32')];

export const server_loads = [];

export const dictionary = {
	"/(site)/(app)": [26,[6,7],[,8]],
	"/(admin)/admin": [9,[2],[3]],
	"/(admin)/admin/(logged)/atributos": [10,[2,4],[3,5]],
	"/(admin)/admin/(logged)/atributos/nuevo": [12,[2,4],[3,5]],
	"/(admin)/admin/(logged)/atributos/[attr]": [11,[2,4],[3,5]],
	"/(admin)/admin/(logged)/categorias": [13,[2,4],[3,5]],
	"/(admin)/admin/(logged)/categorias/[category]": [14,[2,4],[3,5]],
	"/(admin)/admin/cotizaciones": [25,[2],[3]],
	"/(admin)/admin/(logged)/cuenta": [15,[2,4],[3,5]],
	"/(admin)/admin/(logged)/historial": [16,[2,4],[3,5]],
	"/(admin)/admin/(logged)/historial/[change]": [17,[2,4],[3,5]],
	"/(admin)/admin/(logged)/imagenes": [18,[2,4],[3,5]],
	"/(admin)/admin/(logged)/imagenes/[img]": [19,[2,4],[3,5]],
	"/(admin)/admin/(logged)/pdfs": [20,[2,4],[3,5]],
	"/(admin)/admin/(logged)/pdfs/[pdf]": [21,[2,4],[3,5]],
	"/(admin)/admin/(logged)/productos": [22,[2,4],[3,5]],
	"/(admin)/admin/(logged)/productos/[product]": [23,[2,4],[3,5]],
	"/(admin)/admin/[...catch_all]": [24,[2],[3]],
	"/(site)/(app)/clientes": [27,[6,7],[,8]],
	"/(site)/(app)/contacto": [28,[6,7],[,8]],
	"/(site)/(folletos)/folletos/[slug]": [32,[6]],
	"/(site)/(app)/listado/[...category]": [29,[6,7],[,8]],
	"/(site)/(app)/productos/[product]": [30,[6,7],[,8]],
	"/(site)/(app)/proveedores": [31,[6,7],[,8]]
};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),
};